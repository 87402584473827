<template>
  <div class="Client">
    <!-- BEGIN: Content-->
    <div class="app-content content">
      <div class="content-overlay"></div>
      <div class="header-navbar-shadow"></div>
      <div class="content-wrapper container-xxl p-0">
        <banner
          title="TRANSACTIONS"
          :breadcrumb="[
            {
              label: 'Dashboard',
            },
            { label: 'Transactions' },
          ]"
        >
        </banner>

        <div class="row" style="margin-top: -45px">
          <div id="printableArea" class="col-xl-12 col-md-12 col-lg-12 col-12">
            <div class="card">
              <div class="card-body font" style="background-color: #ffe6e6;border-radius: 10px 10px 0px 0px;">
                <div class="row">
                  <div
                    class="col-xl-6 col-md-6 col-lg-6 col-6 text-start"
               
                  >
                  <div class="logo-img">
                      <img class="d-none d-md-block d-lg-block d-xl-block" style="width:25%" src="/assets/image/vakilgiri.png" />
                      <img class="d-block d-md-none d-lg-none d-xl-none" style="width:75%" src="/assets/image/vakilgiri.png" />
                    </div>
                    <div>
                      <strong>Address: </strong
                      ><span
                        >{{admin.address}} <br>{{admin.city}} {{admin.state}} {{admin.pin}}</span
                      >
                    </div>
                    <div><strong>GSTIN: </strong><span>{{admin.gstin}}</span></div>
                    <div><strong>TDS: </strong><span>{{admin.tds}}</span></div>
                      <div><strong>Email: </strong><span>{{admin.email}}</span></div>
                  </div>
                  <div class="col-xl-6 col-md-6 col-lg-6 col-6 text-end"  >
                    <h4 style="color: #00364f;">
                      <b>{{admin.name}}</b>
                    </h4>
                    <div>
                      <!-- <span style="color: black">
                        Upper Bazar, Mallital Nainital Uttarakhand</span
                      > -->
                  
                      <div><strong>NAME: </strong><span>{{admin.accountName}}</span></div>
                      <div><strong>AC/No: </strong><span>{{admin.accountNo}}</span></div>
                      <div><strong>IFSC: </strong><span>{{admin.ifsc}}</span></div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card-body shadow-lg rounded">
                <!-- <div class="row mt-1" style="color: black">
                  <div class="col font text-start">
                    <div>
                      <span> Account statement for : </span>
                      <strong> 12 September 2022 to 25 September 2022</strong>
                    </div>
                  </div>
                  <div class="col text-end">
                    <div>
                      <span> ACCOUNT OPENED ON: </span>

                      <strong> 12th Sep 2022</strong>
                    </div>
                  </div>
                </div> -->

                <!-- <div class="row mt-1 font">
                  <div
                    class="col-xl-3 col-md-3 col-lg-3 col-12"
                    style="color: black; padding: 5px 5px 5px 5px"
                  >
                    <div class="border" style="padding: 10px 10px 10px 12px; height: 85%">
                      <h4><strong style="color: black">₹0.00</strong></h4>
                      <p>OPENING BALANCE</p>
                    </div>
                  </div>
                  <div
                    class="col-xl-3 col-md-3 col-lg-3 col-12"
                    style="color: black; padding: 5px 5px 5px 5px"
                  >
                    <div class="border" style="padding: 10px 10px 10px 12px; height: 85%">
                      <h4><strong style="color: black">₹4,000.00</strong></h4>
                      <p>TOTAL DEPOSIT</p>
                    </div>
                  </div>
                  <div
                    class="col-xl-3 col-md-3 col-lg-3 col-12"
                    style="color: black; padding: 5px 5px 5px 5px"
                  >
                    <div class="border" style="padding: 10px 10px 10px 12px; height: 85%">
                      <h4><strong style="color: black">₹2,391.00</strong></h4>
                      <p>TOTAL WITHDRAWAL</p>
                    </div>
                  </div>
                  <div
                    class="col-xl-3 col-md-3 col-lg-3 col-12"
                    style="color: black; padding: 5px 5px 5px 5px"
                  >
                    <div class="border" style="padding: 10px 10px 10px 12px; height: 85%">
                      <h4><strong style="color: #33cc00">₹1,609.00</strong></h4>
                      <p>CLOSING BALANCE</p>
                    </div>
                  </div>

       
                </div>
                <div class="row border p-1" style="margin: 0px 10px 10px 10px">
                  <div
                    class="col-xl-2 col-md-2 col-lg-2 col-6"
                    style="color: black; padding: 3px 3px 3px 3px"
                  >
                    <p style="margin-bottom: 5px; line-height: 0.5rem">ACCOUNT NUMBER</p>
                    <small><strong style="color: black">917668477033</strong></small>
                  </div>
                  <div
                    class="col-xl-2 col-md-2 col-lg-2 col-6"
                    style="color: black; padding: 3px 3px 3px 3px"
                  >
                    <p style="margin-bottom: 5px; line-height: 0.5rem">ACCOUNT TYPE</p>
                    <small><strong style="color: black">SAVINGS</strong></small>
                  </div>
                  <div
                    class="col-xl-2 col-md-2 col-lg-2 col-6"
                    style="color: black; padding: 3px 3px 3px 3px"
                  >
                    <p style="margin-bottom: 5px; line-height: 0.5rem">IFSC</p>
                    <small><strong style="color: black">PYTM0123456</strong></small>
                  </div>
                  <div
                    class="col-xl-2 col-md-2 col-lg-2 col-6"
                    style="color: black; padding: 3px 3px 3px 3px"
                  >
                    <p style="margin-bottom: 5px; line-height: 0.5rem">MICR</p>
                    <small><strong style="color: black">110766001</strong></small>
                  </div>
                  <div
                    class="col-xl-2 col-md-2 col-lg-2 col-6"
                    style="color: black; padding: 3px 3px 3px 3px"
                  >
                    <p style="margin-bottom: 5px; line-height: 0.5rem">INTEREST RATE</p>
                    <small><strong style="color: black">2.5%</strong></small>
                  </div>
                  <div
                    class="col-xl-2 col-md-2 col-lg-2 col-6"
                    style="color: black; padding: 3px 3px 3px 3px"
                  >
                    <p style="margin-bottom: 5px; line-height: 0.5rem">NOMINEE</p>
                    <small><strong style="color: black">MUKUL GARG</strong></small>
                  </div>
                </div>
                <div class="row p-1">
                  <div class="col-xl-2 col-lg-2 col-md-2 col-sm-2 col-12 text-center">
                    <img
                      style="width: 40%"
                      src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSFtELOsWBOKC0RJTxqFmrC-MOh4PcfEHBFzg&usqp=CAU"
                    />
                  </div>
                  <div class="col-xl-10 col-lg-10 col-md-10 col-sm-10 col-12 text-dark">
                    <small>
                      NEVER SHARE your card number, CVV, PIN, OTP, Internet Banking User
                      ID, Password or URN with anyone, even if the caller claims to be a
                      bank employee. Sharing these details can lead to unauthorised access
                      to your account
                    </small>
                  </div>
                </div> -->

                <div
                  style="
                    background-color: #f21300;
                    height: 7px;
                    width: 104%;
                    margin-left: -2%;
                  "
                ></div>
                <div
                  style="
                    background-color: #00364f;
                    height: 7px;
                    width: 104%;
                    margin-left: -2%;
                  "
                ></div>
                <div class="row mt-2">
                  <div class="col-6 text-start">
                    <h4 style="color: #00364f;">
                    <b>TRANSACTIONS</b>
                  </h4>
                  </div>
                  <div class="col-6 text-end">
                    <button
                    data-bs-toggle="modal"
                    data-bs-target="#new-task-modal"
                    title="Filter"
                    class="btn btn-sm text-white"
                    style="background-color: #00364f"
                  >
                    <strong> <font-awesome-icon icon="filter" /></strong>
                  </button>
                  </div>
                  <div
                    class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"
                    style="margin-top: 7px"
                  >
                    <div class="container-fluid table-scroll">
                      <table class="table table-hover table-sm">
                        <tr
                          class="text-white table-wrapper-scroll-y rounded-circle"
                          style="background-color: black"
                        >
                          <th class="text-truncate">DATE & TIME &nbsp;  &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</th>
                          <th class="text-truncate">TRANSACTION DETAILS &nbsp; &nbsp;</th>
                          <th class="text-end text-truncate" >AMOUNT</th>
                        </tr>

                        <tbody>
                          <tr
                            style="color: black"
                            v-for="(transaction, index) in transections"
                            :key="index"
                          >
                            <td>
                              <img style="width: 15px" src="/assets/image/stop-watch.png" alt="" />&nbsp;
                              <small>{{ dateTime(transaction.created_at) }}</small
                              >
                            </td>
                            <td>
                              <div class="row">
                                <div class="col-2 col-xl-1 col-md-1 pt-2">
                                  <img
                                    v-if="transaction.fromuser_type == 'distributer'"
                                    style="width: 30px"
                                    src="/assets/image/wallet.png"/>
                                  <img
                                    v-else
                                    style="width: 30px"
                                    src="/assets/image/payments.png"
                                  />
                                </div>
                                <div class="col-10 col-xl-11 col-md-11">
                                  <strong
                                    v-if="transaction.fromuser_type == 'distributer'"
                                    style="color: #000000;"
                                    >Money Paid 
                                    <!-- <span v-if="transaction.merchant_vpa"> via UPI</span>
                                    <span v-else>Bank Account</span> -->
                                  </strong>
                                  <strong v-else style="color: #33cc00;"
                                    >Money Received
                                    <!-- <span v-if="transaction.merchant_vpa"> via UPI</span>
                                    <span v-else>In Account</span> -->
                                  </strong>
                                  <br />
                                  <strong style="color: #000000;"
                                  v-if="transaction.fromuser_type == 'distributer'"  >Paid To:    </strong
                                  >
                                  <strong style="color: #000000;"
                                  v-else  >Received from:    </strong
                                  >
                                  <small v-if="transaction.fromuser">  {{transaction.fromuser.name}}
                                      <div>{{transaction.fromuser.code}} ({{ transaction. fromuser_type}} )</div>
                                    </small>
                                  

                                  <small>
                                    <span v-if="transaction.merchant_vpa"
                                      >UPI: {{ transaction.merchant_vpa }} <br
                                    /></span>
                                    <span v-if="transaction.txnType"
                                      >TXN: {{ transaction.txnType }} <br
                                    /></span>
                                    <span v-if="transaction.wallet_withdrawal"
                                      ><span
                                        v-if="
                                          transaction.wallet_withdrawal.beneficiaryaccount
                                        "
                                        >A/C No:{{
                                          transaction.wallet_withdrawal.beneficiaryaccount
                                            .account_number
                                        }}
                                        <br
                                      /></span>
                                    </span>
<span v-if="transaction.txn">
  Transaction ID: {{ transaction.txn }} <br />
</span>

                             <span v-if="transaction.txnRefNo">
                              Reference Number:  {{ transaction.txnRefNo }} 
</span>     </small
                                  >
                                </div>
                              </div>
                            </td>
                            <td class="text-end">
                              <span
                                v-if="transaction.fromuser_type == 'distributer'"
                                style="color: #ff0000"
                                >₹ -{{ transaction.amount }}</span
                              >

                              <span v-else style="color: #33cc00"
                                >₹ +{{ transaction.amount }}</span
                              >
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>

                <!-- <div class="row mt-3">
                  <hr />
                  <div class="col text-start">
                    <small style="color: black">
                      Lorem Ipsum is simply dummy text of the printing and typesetting
                      industry. Lorem Ipsum has been the industry's standard dummy text
                      ever since the 1500s, when an unknown printer took a galley of type
                      and scrambled it to make a type specimen book.
                    </small>
                  </div>
                </div> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>



  <!-- Right Sidebar starts -->
  <div class="modal modal-slide-in sidebar-todo-modal fade" id="new-task-modal">
    <div class="modal-dialog sidebar-sm">
      <div class="modal-content p-0">
        <form
          id="form-modal-todo"
          class="todo-modal needs-validation"
          novalidate
          onsubmit="return false"
        >
          <div
            class="modal-header align-items-center mb-1"
            style="background-color: #00364f"
          >
            <h5 class="modal-title text-white"><strong>Project Filter</strong></h5>
            <div
              class="todo-item-action d-flex align-items-center justify-content-between ms-auto text-white"
            >
              <span class="todo-item-favorite cursor-pointer me-75 text-white"
                ><i data-feather="star" class="font-medium-2"></i
              ></span>
              <i
                data-feather="x"
                class="cursor-pointer"
                data-bs-dismiss="modal"
                stroke-width="3"
                >X</i
              >
            </div>
          </div>
          <div class="modal-body flex-grow-1 pb-sm-0 pb-3">
            <ul class="list-group list-group-flush">
              <li class="list-group-item">
                <div class="form-check">
                  <input
                  @click="valueNullFilter()"
                    v-model="filterType"
                    value="date"
                    class="form-check-input"
                    type="radio"
                    name="flexRadioDefault"
                    id="date"
                  />
                  <label class="form-check-label" for="date"> Date </label>
                </div>
              </li>

              <li class="list-group-item">
                <div class="form-check">
                  <input
                  @click="valueNullFilter()"
                    v-model="filterType"
                    value="merchant_vpa"
                    class="form-check-input"
                    type="radio"
                    name="flexRadioDefault"
                    id="merchant_vpa"
                  />
                  <label class="form-check-label" for="merchant_vpa"> UPI ID </label>
                </div>
              </li>
              <li class="list-group-item">
                <div class="form-check">
                  <input
                  @click="valueNullFilter()"
                    v-model="filterType"
                    value="adminPay"
                    class="form-check-input"
                    type="radio"
                    name="flexRadioDefault"
                    id="adminPay"
                  />
                  <label class="form-check-label" for="adminPay">
                    Admin Pay
                  </label>
                </div>
              </li>
              <!-- <li class="list-group-item">
                <div class="form-check">
                  <input
                  @click="valueNullFilter()"
                    v-model="filterType"
                    value="txn"
                    class="form-check-input"
                    type="radio"
                    name="flexRadioDefault"
                    id="txn"
                  />
                  <label class="form-check-label" for="txn"> TXN </label>
                </div>
              </li> -->
              <!-- <li class="list-group-item">
                <div class="form-check">
                  <input
                  @click="valueNullFilter()"
                    v-model="filterType"
                    value="txnRefNo"
                    class="form-check-input"
                    type="radio"
                    name="flexRadioDefault"
                    id="txnRefNo"
                  />
                  <label class="form-check-label" for="txnRefNo">
                    TXN Refrence No
                  </label>
                </div>
              </li> -->

              <li class="list-group-item">
                <div class="form-check">
                  <input
                  @click="valueNullFilter()"
                    v-model="filterType"
                    value="status"
                    class="form-check-input"
                    type="radio"
                    name="flexRadioDefault"
                    id="status"
                  />
                  <label class="form-check-label" for="status"> Status </label>
                </div>
              </li>

              <li class="list-group-item">
                <div class="form-check">
                  <input
                  @click="valueNullFilter()"
                    v-model="filterType"
                    value="service_id"
                    class="form-check-input"
                    type="radio"
                    name="flexRadioDefault"
                    id="service_id"
                  />
                  <label class="form-check-label" for="service_id">
                    Service
                  </label>
                </div>
              </li>
              <li class="list-group-item">
                <div class="form-check">
                  <input
                  @click="valueNullFilter()"
                    v-model="filterType"
                    value="all"
                    class="form-check-input"
                    type="radio"
                    name="flexRadioDefault"
                    id="all"
                  />
                  <label class="form-check-label" for="all"> All </label>
                </div>
              </li>
            </ul>

            <div class="pt-1 pb-1">
              <!-- <label for="exampleFormControlInput1" class="form-label">Email address</label> -->
              <input
                type="date"
                v-if="
                  filterType == 'date' ||
                  filterType == 'dueDate' ||
                  filterType == 'completionDate'
                "
                class="form-control datepicker"
                v-model="filterValue"
                id="exampleFormControlInput1"
                placeholder="Enter Value"
              />
              <input
                type="text"
                v-if="
                  filterType == 'clientServiceNo' ||
                  filterType == 'merchant_vpa' ||
                  filterType == 'txn' ||
                  filterType == 'txnRefNo'
                "
                class="form-control"
                v-model="filterValue"
                id="exampleFormControlInput1"
                placeholder="Enter Value"
              />
              <input
                type="text"
                v-if="filterType == 'client_id'"
                @change="findRecordeByPanId"
                class="form-control"
                v-model="filterValue"
                id="exampleFormControlInput1"
                placeholder="Enter Value"
              />

              <select
                v-model="filterValue"
                v-if="filterType == 'status'"
                class="form-select"
                aria-label="Default select example"
              >
                <option selected disabled>Please Select...</option>
                <option value="pending">Pending</option>
              </select>

              <select
                v-model="filterValue"
                v-if="filterType == 'adminPay'"
                class="form-select"
                aria-label="Default select example"
              >
                <option selected disabled>Please Select...</option>
                <option value="yes">Yes</option>
                <option value="no">No</option>
              </select>

              <select
                v-model="filterValue"
                v-if="filterType == 'service_id'"
                class="form-select"
                aria-label="Default select example"
              >
                <option selected disabled>Please Select...</option>
                <option
                  v-for="(service, index) in services"
                  :key="index"
                  :value="service.id"
                >
                  {{ service.name }}
                </option>
              </select>
            </div>

            <button
              v-if="filterType != 'client_id'"
              @click="loadTansactions()"
              type="button"
              class="btn btn-success btn-sm"
            >
              Success
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
  <!-- Right Sidebar ends -->


  <div v-if="loading == true">
          <Spinner />
        </div>
</template>

<script>
import moment from "moment";
import Banner from "../../../components/distributer/comman/Banner.vue";
import Spinner from "../../../components/distributer/comman/Spinner.vue";
// import Form from 'vform'
// import jQuery from "jquery";
// let $ = jQuery
export default {
  name: "DistributerTransections",
  retailer_id: "",
  components: {
    Banner,
    Spinner,
  },
  data() {
    return { 
      loading: false,
      services: [],
      filterValue: "",
      filterType: "",
      transections: [],
      admin:{} };
  },
  methods: {
    servicesLoad() {
      this.$axios
        .post(
          `allservice`,
          {},
          {
            headers: { Authorization: "Bearer " + localStorage.accessToken },
          }
        )
        .then((res) => {
          this.services = res.data.data;
          console.log(res.data.data);
        });
    },
    loadSuperAdmin() {
      this.$axios
        .get("setting", {
          headers: { Authorization: "Bearer " + localStorage.accessToken },
        })
        .then((res) => {
          console.log(res.data);
          this.admin = res.data;


          // if (this.admin.logo != "null") {
          //   this.proUpdatelogo  =
          //     this.$store.state.imgUrl +
          //     "/logo/" +
          //     this.admin.id +
          //     "/thumbs/" +
          //     this.admin.logo;
          // }

        });
    },
    dateTime(value) {
      console.log('===========================*********************===================');


//       const inputDateTime = '2023-10-29T06:43:26.000000Z'; 
// const formattedDateTime = moment(inputDateTime).format('DD-MM-YY hh:mm:ss A');

// console.log(formattedDateTime);
// console.log('===========================*********************===================');

      
      return moment(value).format("DD-MM-YYYY,  HH:mm ");

    },
    valueNullFilter(){
  this.filterValue = "";
},
    loadTansactions() {

      if (this.filterType == "all") {
        this.filterValue = "";
        this.filterType = "";
      }

      var pageUrl = "";

      if (this.filterValue != null && this.filterType != null) {
        pageUrl += `distributer/transaction?all=all&${this.filterType}=${this.filterValue}`;
      } else {
        pageUrl += `distributer/transaction?all=all`;
      }
      this.loading = true;
      this.$axios
        .get(pageUrl, {
          headers: { Authorization: "Bearer " + localStorage.accessToken },
        })
        .then((res) => {
          console.log("==================================");
          this.transections = res.data.data;
          console.log(res.data.data);
        })
        .finally(() => (this.loading = false))

      // this.$axios
      //   .get("superadmin/transaction?all=all", {
      //     headers: { Authorization: "Bearer " + localStorage.accessToken },
      //   })
      //   .then((res) => {
      //     console.log("==================================");
      //     this.transections = res.data.data;
      //     console.log(res.data.data.data);
      //   });
    },
  },
  created() {
    if (
      this.$route.meta.redirectIfLoggedIn == "true" &&
      localStorage.getItem("accessToken") == null
    ) {
      this.$router.push({ name: "Login" });
    } else {
      this.loadTansactions();
      this.loadSuperAdmin();
      this.servicesLoad();
    }
  },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap");

.font {
  font-family: "Poppins", sans-serif;
}

@media print {
  body {
    -webkit-print-color-adjust: exact;
  }

  .col-sm-1,
  .col-sm-2,
  .col-sm-3,
  .col-sm-4,
  .col-sm-5,
  .col-sm-6,
  .col-sm-7,
  .col-sm-8,
  .col-sm-9,
  .col-sm-10,
  .col-sm-11,
  .col-sm-12 {
    float: left;
  }

  .col-sm-12 {
    width: 100%;
  }

  .col-sm-11 {
    width: 91.66666666666666%;
  }

  .col-sm-10 {
    width: 83.33333333333334%;
  }

  .col-sm-9 {
    width: 75%;
  }

  .col-sm-8 {
    width: 66.66666666666666%;
  }

  .col-sm-7 {
    width: 58.333333333333336%;
  }

  .col-sm-6 {
    width: 50%;
  }

  .col-sm-5 {
    width: 41.66666666666667%;
  }

  .col-sm-4 {
    width: 33.33333333333333%;
  }

  .col-sm-3 {
    width: 25%;
  }

  .col-sm-2 {
    width: 16.666666666666664%;
  }

  .col-sm-1 {
    width: 8.333333333333332%;
  }
}

.form-check-input:checked {
  background-color: #ffffff;
  border-color: #f21000;
  border-width: 5px;
}
.form-check-input:not(:disabled):checked {
  box-shadow: 0 2px 4px 0 rgb(225 0 0 / 28%);
}
</style>
